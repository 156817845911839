<template>
	<div class="site">
		<ZyroSvg
			:class="{ disabled: disabled }"
			class="domain-input-bg display-none-tablet"
			name="domain-input"
		/>
		<div class="domain-input">
			<label
				class="label"
				for="domain"
			>
				https://
			</label>
			<div
				ref="measureInput"
				class="measure-input"
			/>
			<input
				id="domain"
				ref="inputDomain"
				data-qa="builder-publishmodal-inputfield-freedomain"
				type="text"
				class="input-field"
				spellcheck="false"
				autocomplete="off"
				:value="value"
				:readonly="readonly"
				data-hj-whitelist
				@input="updateInput($event.target.value)"
				@focus="onFocus($event)"
			>
			<label
				v-if="!hideSubdomain"
				class="label"
				for="domain"
			>
				{{ zyroDomain }}
			</label>
		</div>
	</div>
</template>

<script>
import { isDomainAvailable } from '@/api/PublishApi';
import { debounce } from '@/utils/debounce';

export default {
	props: {
		readonly: {
			type: Boolean,
			default: false,
			required: false,
		},
		value: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		hideSubdomain: { type: Boolean },
		isFocused: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			zyroDomain: process.env.VUE_APP_PUBLISH_DOMAIN,
			inputTextSelected: false,
			initialValue: '',
		};
	},
	mounted() {
		this.measureInputWidth(this.value);
		this.$refs.inputDomain.focus();

		this.initialValue = this.value;
	},
	methods: {
		onInput() {
			this.$emit('focus-input');
		},
		measureInputWidth(inputText) {
			this.$refs.measureInput.textContent = inputText;
			this.$refs.inputDomain.style.width = `${this.$refs.measureInput.offsetWidth + 1}px`;
		},
		checkDomain: debounce(async function debouncedDomainCheck() {
			try {
				const result = await isDomainAvailable(`${this.value}${process.env.VUE_APP_PUBLISH_DOMAIN}`);

				this.$emit(result ? 'on-valid' : 'on-invalid');
			} catch (error) {
				this.$emit('on-error', error);
			}

			if (!this.$refs.inputDomain.value) {
				this.$emit('clear-message');
			}

			this.$emit('on-loading-end');
		}, 500),
		updateInput(value) {
			const filteredValue = value
				.replace(/[^\dA-Za-z-]+/g, '')
				.trim()
				.toLowerCase();

			this.$refs.inputDomain.value = filteredValue;
			this.measureInputWidth(filteredValue);

			if (value === '') {
				this.$emit('input', value);
				this.$emit('on-loading-end');
				this.$emit('clear-message');

				return;
			}

			if (filteredValue === value) {
				this.$emit('on-loading');
				this.$emit('input', filteredValue);
				this.checkDomain();
			}
		},
		onFocus(event) {
			if (this.isFocused && !this.inputTextSelected) {
				event.target.select();
				this.onInput();
				this.inputTextSelected = true;
			} else {
				this.$refs.inputDomain.selectionStart = this.$refs.inputDomain.selectionEnd;
				this.inputTextSelected = false;
			}
		},
	},
};
</script>
<style lang="scss">
// When this is refactored, update typography as well.

.site {
	position: relative;
	display: flex;

	> .domain-input-bg {
		transition: all 0.2s;

		&.disabled {
			filter: grayscale(1);
		}
	}

	> .domain-input {
		position: absolute;
		top: 1px;
		bottom: 0;
		left: 88px;
		display: flex;
		width: 355px;
		height: 32px;
		padding-top: 6px;
		padding-left: 12px;
		margin-top: auto;
		margin-bottom: auto;
		background: white;
		border-radius: 25px;
		transition: all 0.3s ease;

		&.readonly {
			pointer-events: none;
		}

		&:focus-within {
			background: #efefef;

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				content: '';
				background: transparent;
				border: 2px solid #007aff4a;
				border-radius: 25px;
				opacity: 1;
				transform: scale(1.01) translate(0, 0);
			}
		}

		&:hover {
			background: #efefef;
		}

		> .label {
			font-size: 14px;
			font-style: normal;
			font-weight: normal;
			font-stretch: normal;
			line-height: 1.43;
			color: $grey-600;
			user-select: none;

			&:last-child {
				flex-grow: 1;
			}
		}

		> .input-field {
			margin-bottom: 6px;
			font-family: $font-family;
			font-size: 14px;
			line-height: 1.43;
			letter-spacing: 0.6px;
			background: transparent;
			border: none;

			&:focus {
				outline: none;
			}

			&::selection {
				background: #007aff4a;
			}
		}

		> .measure-input {
			position: absolute;
			display: inline;
			font-size: 14px;
			line-height: 1.43;
			letter-spacing: 0.6px;
			pointer-events: none;
			visibility: hidden;
		}
	}

	> .link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 366px;
		height: 50px;
		font-size: 14px;
		font-style: normal;
		font-weight: normal;
		font-stretch: normal;
		line-height: 1.57;
		color: #6d6d6d;
		letter-spacing: 0.7px;
		background: #f2eff9;
	}

	> .btn-link {
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 143px;
		height: 50px;
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		font-stretch: normal;
		line-height: normal;
		color: #583bb6;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 2.6px;
		border: solid 1.5px #f2eff9;
	}
}

@include zyro-media($media-tablet) {
	.site .domain-input {
		position: static;
		display: block;
		width: 100%;
		height: auto;
		padding: 10px;
		background: $grey-100;
		border-radius: 0;

		&:focus-within::before {
			display: none;
		}
	}
}
</style>
