<template>
	<ZyroModal
		max-width="600px"
		max-height="402px"
		class="connectYourDomainModal"
		@close-modal="userClosesModal"
	>
		<h4 class="connectYourDomainModal__title z-h4">
			{{ $t('builder.connectYourDomainModal.title') }}
		</h4>
		<p>{{ $t('builder.connectYourDomainModal.text') }}</p>
		<ZyroButton
			theme="primary"
			color="plump-purple"
			size="sm"
			class="connectYourDomainModal__button"
			data-qa="builder-connectyourdomainmodal-btn-chooseplan"
			@click="redirectToPayments"
		>
			{{ $t('builder.connectYourDomainModal.btn') }}
		</ZyroButton>

		<p>
			{{ $t('builder.connectYourDomainModal.footerTextPrice', [lowestBasicPlanPrice]) }}
		</p>
		<p>
			<strong>{{ $t('builder.connectYourDomainModal.footerTextGuarantee') }}</strong>
		</p>
	</ZyroModal>
</template>

<script>
import { mapGetters } from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { useRedirects } from '@/use/useRedirects';

export default {
	setup() {
		const { redirectToWWWPayments } = useRedirects();

		return { redirectToWWWPayments };
	},
	computed: { ...mapGetters('subscription', ['lowestBasicPlanPrice']) },
	mounted() {
		EventLogApi.logEvent({ eventName: 'builder.connect_domain_modal.loaded' });
	},
	methods: {
		userClosesModal() {
			EventLogApi.logEvent({ eventName: 'builder.connect_domain_modal.click_button_close' });
			this.$emit('close');
		},
		redirectToPayments() {
			EventLogApi.logEvent({ eventName: 'builder.connect_domain_modal.click_button_choose_plan' });
			this.redirectToWWWPayments({ discount: true });
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .modal .modal__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 48px;
	overflow: visible;
	text-align: center;
}

.connectYourDomainModal {
	&__title {
		margin-bottom: 16px;
		text-align: center;
	}

	&__button {
		margin: 48px 0;
	}
}
</style>
