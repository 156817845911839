<template>
	<ZyroModal
		max-width="758px"
		max-height="645px"
		class="publish-modal"
		position="bottom"
		@close-modal="userClosesModal"
	>
		<h2 class="publish-modal__title z-h4">
			{{ $t('builder.publishModal.title') }}
		</h2>
		<p class="publish-modal__subtitle z-body-small">
			{{ $t('builder.publishModal.subtitle') }}
		</p>
		<form @submit.prevent="modalAction">
			<div
				class="publish-modal__plan publish-modal__plan--free"
				data-qa="builder-publishmodal-btn-freedomain"
				:class="{ 'publish-modal__plan--active': selectedPlan === 'free' }"
				@click="selectedPlan = 'free'"
			>
				<ZyroRadio
					id="free"
					ref="freePlanRadio"
					v-model="selectedPlan"
					value="free"
					class="publish-modal__plan-radio"
				/>
				<div class="publish-modal__plan-text">
					<div class="publish-modal__plan-label z-body-small z-body-small--strong">
						{{ $t('builder.publishModal.form.label') }}
					</div>
					<ZyroDomainInput
						v-model="domain"
						class="publish-modal__domain-input"
						:disabled="selectedPlan !== 'free'"
						@on-valid="isDomainValid = true"
						@on-invalid="isDomainValid = false"
						@on-loading="isDomainLoading = true"
						@on-error="isDomainValid = false"
						@on-loading-end="isDomainLoading = false"
						@clear-message="isDomainValid = null"
						@focus-input="$emit('focus-domain-input')"
					/>
					<Transition name="fade">
						<div
							v-show="selectedPlan === 'free'"
							class="publish-modal__message"
						>
							<ZyroLoader
								v-if="isDomainLoading"
								:style="{ '--size': '18px' }"
							/>
							<template v-if="isDomainValid && !isDomainLoading">
								<i18n path="builder.publishModal.form.validDomainMessage">
									👍
								</i18n>
							</template>
							<template v-else-if="!isDomainValid && !isDomainLoading && isDomainValid !== null">
								<div class="publish-modal__message--error">
									😬 {{ $t('validate.domainNameTaken') }}
								</div>
							</template>
							<template v-else-if="!isDomainLoading">
								<div
									class="publish-modal__message publish-modal__message--baloon display-none-tablet"
								>
									{{ $t('builder.publishModal.form.message') }}
								</div>
							</template>
						</div>
					</Transition>
				</div>
			</div>
			<div
				class="publish-modal__plan publish-modal__plan--paid"
				data-qa="builder-publishmodal-btn-customdomain"
				:class="{ 'publish-modal__plan--active': selectedPlan === 'paid' }"
				tabindex="0"
				@keydown.enter="modalAction"
				@click="selectedPlan = 'paid'"
			>
				<ZyroRadio
					id="paid"
					ref="paidPlanRadio"
					v-model="selectedPlan"
					value="paid"
					class="publish-modal__plan-radio"
				/>
				<div class="publish-modal__plan-text">
					<div class="publish-modal__plan-text-block">
						<div class="publish-modal__plan-label z-body-small z-body-small--strong">
							{{ $t('builder.publishModal.label') }}
						</div>
						<ZyroPill
							v-if="hasPlan"
							class="publish-modal__plan-discount-premium"
							:text="$t('builder.publishModal.additionalPremiumMessage')"
							:subtext="$t('builder.publishModal.subtext')"
							text-class="z-body-small z-body-small--strong"
						/>
						<ZyroPill
							v-else
							class="publish-modal__plan-discount"
							:text="$t('builder.publishModal.discount')"
							:subtext="$t('builder.publishModal.text')"
							text-class="z-body-small z-body-small--strong"
						/>
					</div>
					<div class="publish-modal__plan-description z-body-small">
						{{ $t('builder.publishModal.description') }}
					</div>
				</div>
			</div>
		</form>
		<template #footer>
			<ZyroButton
				data-qa="builder-publishmodal-btn-continue"
				class="publish-modal__action"
				:disabled="isModalActionsDisabled"
				theme="primary"
				@click="modalAction"
			>
				{{ $t('common.continue') }}
			</ZyroButton>
		</template>
		<Portal
			v-if="showConnectYourDomainModal"
			to="app"
		>
			<ConnectYourDomainModal
				v-if="showConnectYourDomainModal"
				@close="showConnectYourDomainModal = false"
			/>
		</Portal>
	</ZyroModal>
</template>

<script>
import {
	mapActions,
	mapMutations,
	mapGetters,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import ConnectYourDomainModal from '@/components/builder-modals/modals/ConnectYourDomainModal.vue';
import ZyroDomainInput from '@/components/ui/ZyroDomainInput.vue';
import { DOMAIN_SETTINGS_ROUTE } from '@/router';
import {
	mapActionsGui,
	OPEN_MODAL,
	CLOSE_MODAL,
} from '@/store/builder/gui';

export default {
	components: {
		ZyroDomainInput,
		ConnectYourDomainModal,
	},
	data() {
		return {
			selectedPlan: 'free',
			domain: this.$t('builder.publishModal.placeholderDomain'),
			isDomainValid: null,
			isDomainLoading: false,
			showConnectYourDomainModal: false,
		};
	},
	computed: {
		...mapGetters('subscription', ['hasPlan']),
		isModalActionsDisabled() {
			return this.isDomainLoading || (!this.isDomainValid && this.selectedPlan === 'free');
		},
	},
	watch: {
		selectedPlan(planName) {
			if (planName === 'paid') {
				EventLogApi.logEvent({ eventName: 'builder.publish_modal.select_custom_domain' });
			}
		},
	},
	mounted() {
		EventLogApi.logEvent({ eventName: 'builder.publish_modal.loaded' });
	},
	methods: {
		...mapMutations('gui', ['toggleSiteBeingPublished']),
		...mapMutations(['setDomain']),
		...mapActions('subscription', ['autoConnectPlan']),
		...mapActionsGui({
			openModal: OPEN_MODAL,
			closeModal: CLOSE_MODAL,
		}),
		async modalAction() {
			if (this.isModalActionsDisabled) {
				return;
			}

			if (this.selectedPlan === 'free') {
				EventLogApi.logEvent({ eventName: 'builder.publish_modal.click_button_continue.free_domain' });
				this.setDomain(this.domain);
				this.toggleSiteBeingPublished();
			} else if (this.selectedPlan === 'paid') {
				await this.autoConnectPlan();
				EventLogApi.logEvent({ eventName: 'builder.publish_modal.click_button_continue.custom_domain' });
				this.publishWithPaidPlan();

				return;
			}

			this.closeModal();
		},
		publishWithPaidPlan() {
			return this.hasPlan
				? this.navigateToConnectDomainPage()
				: this.openConnectDomainModal();
		},
		navigateToConnectDomainPage() {
			this.$router.push({ name: DOMAIN_SETTINGS_ROUTE });
			this.closeModal();
		},
		openConnectDomainModal() {
			this.showConnectYourDomainModal = true;
		},
		userClosesModal() {
			EventLogApi.logEvent({ eventName: 'builder.publish_modal.close' });
			this.closeModal();
		},
	},
};
</script>

<style lang="scss" scoped>
.publish-modal {
	// TODO: modal options in modal component
	::v-deep .modal__content {
		display: flex;
		flex-direction: column;
		padding: 64px 48px 0 48px;
	}

	&__title {
		text-align: center;
	}

	&__subtitle {
		margin: 8px auto 48px;
		color: $grey-800;
		text-align: center;
	}

	&__plan {
		display: flex;
		padding: 40px 24px;
		margin-bottom: 16px;
		border: 1px solid transparent;
		border-radius: 8px;
		transition: box-shadow 0.2s, border-color 0.2s;

		&--free {
			height: 168px;
		}

		&:hover {
			border-color: $grey-200;
		}

		&:focus {
			outline: none;
		}

		&--active {
			cursor: pointer;
			border-color: $grey-200;
			box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
		}
	}

	&__plan-radio {
		margin-right: 33px;
	}

	&__plan-label {
		margin-right: 10px;
		color: $dark;
	}

	&__plan-description {
		color: $grey-800;
	}

	&__domain-input {
		margin-top: 10px;
	}

	&__plan-text-block {
		display: flex;
		margin-bottom: 10px;
	}

	&__plan-discount,
	&__plan-discount-premium {
		margin-top: -3px;
		font-size: 12px;
		font-weight: 900;
	}

	&__plan-discount-premium {
		color: $light;
		background-color: $success;
	}

	&__message {
		padding: 8px (8px * 2);
		margin: 0 auto 0 20px;
		font-size: 12px;
		text-align: center;

		&--baloon {
			position: relative;
			width: 328px;
			margin-top: -10px;
			color: $light;
			background-color: $accent-two;
			border-radius: 4px;

			&::before {
				position: absolute;
				top: -5px;
				left: 157px;
				display: block;
				width: 13px;
				height: 14px;
				clip-path: polygon(47% 0, 0 42%, 100% 42%);
				content: "";
				background-color: $accent-two;
			}
		}

		&--error {
			color: $secondary;
		}
	}
	// TODO: this should be default?
	::v-deep .modal__footer {
		justify-content: flex-end;
		border-top: none;
	}
}

@include zyro-media($media-tablet) {
	.publish-modal {
		::v-deep .modal__content {
			display: flex;
			flex-direction: column;
			padding: 56px 24px;
		}

		&__plan {
			display: flex;
			padding: 40px 0 0;
			border: none;
			border-top: 1px solid $grey-200;
			border-radius: 0;
			transition: none;

			&-text {
				position: relative;
				width: 100%;
				height: 100px;
			}

			&--active {
				box-shadow: none;
			}
		}

		&__plan-discount,
		&__plan-discount-premium {
			position: absolute;
			bottom: 0;
		}

		&__message {
			padding: 8px 0 0;
			text-align: left;
		}
	}
}
</style>
